import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useArticleUploadPDF } from '@/hooks/mutations/articles/useArticlePDF';
import { useQueryClient } from '@tanstack/react-query';

import { CloudUpload } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export const PDFModal = ({
    onFinish,
    showModal,
    setShowModal,
}: { onFinish: () => void; showModal: boolean; setShowModal: (show: boolean) => void }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [numFiles, setNumFiles] = useState(0);
    const { mutate: uploadPDF } = useArticleUploadPDF();
    const queryClient = useQueryClient();

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isUploading) {
            const startTime = Date.now();
            interval = setInterval(() => {
                const elapsed = (Date.now() - startTime) / 500;
                const progress = Math.min(elapsed / (numFiles || 1), 0.8);
                setUploadProgress(p => Math.max(p, progress));
            }, 100);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isUploading, numFiles]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: async (files: File[]) => {
            if (files.length > 0) {
                const formData = new FormData();
                files.forEach(f => formData.append('pdfs', f));
                setIsUploading(true);
                setNumFiles(files.length);
                setUploadProgress(0);
                uploadPDF(formData, {
                    onSettled: () => {
                        setUploadProgress(1);
                        queryClient.refetchQueries({ queryKey: ['/api/v1/directory'] });
                        setTimeout(() => {
                            setIsUploading(false);
                            setShowModal(false);
                            onFinish();
                        }, 400);
                    },
                });
            }
        },
        accept: {
            'application/pdf': ['.pdf'],
        },
    });

    return (
        <Dialog open={showModal} onOpenChange={setShowModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        <CloudUpload className="size-5" /> Import PDF articles
                    </DialogTitle>
                </DialogHeader>

                <section>
                    <div className="flex flex-col gap-md p-lg">
                        <h3>
                            {isUploading ? 'Uploading your PDFs - this may take a moment' : 'Upload your existing PDFs'}
                        </h3>
                        <div
                            className="p-lg border-grey border border-dashed rounded-md bg-bg-surface cursor-pointer flex items-center justify-start gap-lg"
                            {...(!isUploading && getRootProps())}
                        >
                            {!isUploading && <input {...getInputProps()} />}
                            <div
                                className={`size-10 rounded-full flex items-center justify-center p-sm border-[0.5px] transition-all duration-200 ease-out ${
                                    isUploading || isDragActive
                                        ? 'bg-gradient-to-b from-blue-200 to-blue-500 border-blue shadow-upload'
                                        : 'bg-gradient-to-b from-steel-200 to-steel-500 border-grey'
                                }`}
                            >
                                <div
                                    className={`size-8 bg-bg rounded-full flex items-center justify-center p-md border-[0.5px] shadow-sm transition-all duration-500 ease-out ${
                                        isUploading || isDragActive
                                            ? 'text-body-blue-primary border-blue'
                                            : 'text-body-subtle border-grey'
                                    }`}
                                >
                                    <CloudUpload />
                                </div>
                            </div>
                            {isUploading ? (
                                <div className="w-full h-md bg-bg-elevated rounded-full">
                                    <div
                                        className="transition-all duration-500 ease-out bg-bg-blue-secondary h-full rounded-full"
                                        style={{ width: `${uploadProgress * 100}%` }}
                                    />
                                </div>
                            ) : (
                                <p>
                                    Drag and drop your PDF files here, or{' '}
                                    <span className="text-body-blue-primary font-medium">click to select files</span>
                                </p>
                            )}
                        </div>
                    </div>
                </section>
            </DialogContent>
        </Dialog>
    );
};
