import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import {
    useArticleExternalDirectory,
    useArticleExternalDirectorySelected,
} from '@/hooks/queries/articles/useArticleDirectory';
import { HelpCircle, Info } from 'lucide-react';

import { Loader } from '@/components/loader';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useArticleExternalDirectorySelectedPost } from '@/hooks/mutations/articles/useArticleExternalDirectorySelectedPost';
import {
    useIntegrationReSync,
    useIntegrationSettingsSync,
} from '@/hooks/queries/integrations/useIntegrationSettingsStatus';
import { fmtDateFull } from '@/lib/dates';
import { getProviderAssets } from 'lib/3p';
import type { IntegrationId } from 'lib/integration';
import type { NestableDirectory } from 'lib/models/directory';
import { useEffect, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { type NestableDirectoryWithChecked, NestedCheckboxTree } from './NestedCheckboxTree';

export const SyncArticleDialog = ({ onClose, provider }: { onClose: () => void; provider: IntegrationId }) => {
    const [selectedIds, setSelectedIds] = useState<(string | number)[][] | undefined>();
    const [hovered, setHovered] = useState(false);

    const { data: syncData } = useIntegrationSettingsSync();
    const { data, isLoading } = useArticleExternalDirectory({ provider });
    const { data: selectedData } = useArticleExternalDirectorySelected({
        provider,
    });
    const updateSelected = useArticleExternalDirectorySelectedPost({ provider });
    const syncMutation = useIntegrationReSync({ provider });

    useEffect(() => {
        if (selectedIds === undefined && selectedData && data?.directory) {
            const selectedSet = new Set(selectedData.selected.map(s => s.article.externalId));
            const selectedIds: (string | number)[][] = [];
            data.directory.forEach(d => {
                if (d.type === 'file') {
                    if (selectedSet.has(`${d.id}`)) {
                        selectedIds.push([d.id]);
                    }
                } else {
                    let queue = d.children;
                    const selected = [];
                    while (queue.length > 0) {
                        const files = queue.filter(c => c.type === 'file');
                        queue = queue.filter(c => c.type === 'directory').flatMap(c => c.children);
                        if (files.length > 0) {
                            selected.push(...files.filter(f => selectedSet.has(`${f.id}`)).map(f => f.id));
                        }
                    }
                    selectedIds.push(selected);
                }
            });
            setSelectedIds(selectedIds);
        }
    }, [selectedData, data?.directory, selectedIds]);

    const handleChange = (i: number) => (newSelected: (string | number)[]) => {
        setSelectedIds(prev => {
            const before = prev?.slice(0, i) ?? [];
            const after = prev?.slice(i + 1) ?? [];
            return [...before, newSelected, ...after];
        });
    };

    const addCheckedField = (
        directory: NestableDirectory[number] | NestableDirectoryWithChecked,
    ): NestableDirectoryWithChecked => {
        const children = 'children' in directory ? (directory.children?.map(c => addCheckedField(c)) ?? []) : [];

        const checked =
            children.length > 0
                ? children.every(c => c?.checked)
                : Array.isArray(selectedIds)
                  ? selectedIds.flat().some(s => s === directory.id)
                  : selectedData?.selected.some(s => `${s.article.externalId}` === `${directory.id}`);

        if (directory.type === 'directory') {
            return {
                ...directory,
                checked,
                children: children ?? [],
            } as NestableDirectoryWithChecked;
        }

        return {
            ...directory,
            checked: checked ?? false,
        };
    };
    const dataWithChecked = data?.directory.map(addCheckedField) ?? [];

    const handleSync = () => {
        if (!selectedIds) {
            return;
        }
        updateSelected.mutate(
            { selectedIds: selectedIds.flat() },
            {
                onSuccess: () => {
                    onClose();
                },
            },
        );
    };

    const handleIngest = () => {
        syncMutation.mutate({});
    };

    const lastSynced = syncData?.[provider]?.lastSync;

    const { logo, name } = getProviderAssets(provider);

    const isSpinning = !data?.directory || isLoading;

    const getItemSize = (index: number) => {
        let elements = 0;
        let q = [dataWithChecked[index]];
        while (q.length) {
            elements += q.length;
            q = q.flatMap(c => (c.type === 'directory' ? (c.children ?? []) : []));
        }
        return elements * 30;
    };

    return (
        <Dialog
            open={true}
            onOpenChange={() => {
                onClose();
            }}
        >
            <DialogContent size="max" className="max-w-[800px]">
                <DialogHeader>
                    <DialogTitle>
                        <img src={logo} alt={name} className="size-6" />
                        Sync articles from {name}
                    </DialogTitle>
                </DialogHeader>
                <DialogDescription className="bg-bg-overlay px-lg py-md flex flex-col gap-sm border-grey border-y-[0.5px]">
                    <div className="text-body-grey-primary flex items-center gap-md">
                        <Info />
                        <h3>Syncing</h3>
                    </div>
                    <div>
                        You can manage which articles you would like to sync into Console at any time. Articles will
                        automatically re-sync every hour or you can manually sync changes at any time to ensure your
                        Knowledge Base stays up to date.
                    </div>
                </DialogDescription>
                {isSpinning ? (
                    <div className="flex justify-center items-center h-48">
                        <Loader />
                    </div>
                ) : (
                    <div className="overflow-y-auto px-md py-lg">
                        <List
                            height={500}
                            itemCount={dataWithChecked.length}
                            itemSize={getItemSize} // Set variable sizes
                            width="100%"
                        >
                            {({ index, style }) => (
                                <div style={style}>
                                    <NestedCheckboxTree
                                        data={[dataWithChecked[index]]}
                                        onChange={handleChange(index)}
                                    />
                                </div>
                            )}
                        </List>
                    </div>
                )}
                <DialogFooter className="flex justify-between">
                    <Button mode="borderless" onClick={onClose}>
                        Cancel
                    </Button>
                    <div className="flex items-center gap-md">
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    mode="borderless"
                                    onClick={handleIngest}
                                    className="text-body-subtle hover:text-body-grey-primary w-36"
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                >
                                    {hovered ? (
                                        <>
                                            <img src="/3p/zendesk-logo.png" alt="Zendesk logo" className="size-4" />
                                            Refresh articles
                                        </>
                                    ) : (
                                        <>
                                            <HelpCircle />
                                            Missing articles?
                                        </>
                                    )}
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                Last synced {lastSynced ? fmtDateFull(lastSynced) : 'never'}
                            </TooltipContent>
                        </Tooltip>

                        <Button variant="blue" mode="dark" onClick={handleSync}>
                            Save articles
                        </Button>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
