import { getHeaders } from '@/lib/getHeaders';
import { useMutation } from '@lib/hooks/useMutation';
import { type DefaultError, useMutation as useMutationRaw, useQueryClient } from '@tanstack/react-query';
export const useArticleUploadPDF = () => {
    return useMutationRaw({
        mutationFn: (formData: FormData) => {
            // biome-ignore lint/style/noRestrictedGlobals: can't use baseFetch since it doesn't support multipart/form-data
            return fetch(`${window.location.origin.replace('app', 'api')}/api/v1/articles/pdf`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                body: formData,
                headers: getHeaders(),
            });
        },
    });
};

export const useArticleDeletePDF = (id: string) => {
    const queryClient = useQueryClient();
    return useMutation<{ success: boolean }, DefaultError, void>({
        url: `/api/v1/articles/pdf/${id}`,
        method: 'DELETE',
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: ['/api/v1/directory'] });
        },
    });
};
